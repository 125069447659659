// 保存数据到 sessionStorage
export const setSessionItem = (key, value) => {
    sessionStorage.setItem(key, value)
}
// 从 sessionStorage 获取数据
export const getSessionItem = (key) => {
    return sessionStorage.getItem(key) || ""
}
// 从 sessionStorage 删除保存的数据
export const removeSession = (key) => {
    sessionStorage.removeItem(key);
}
// 从 sessionStorage 删除所有保存的数据
export const cleanAllSession = () => {
    sessionStorage.clear();
}
// 保存数据到 sessionStorage
export const setLocalItem = (key, value) => {
    localStorage.setItem(key, value)
}
// 从 localStorage 获取数据
export const getLocalItem = (key) => {
    return localStorage.getItem(key) || ""
}
// 从 sessionStorage 删除保存的数据
export const removeLocal = (key) => {
    localStorage.removeItem(key);
}
// 从 sessionStorage 删除所有保存的数据
export const cleanAllLocal = () => {
    localStorage.clear();
}
