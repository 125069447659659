<template>
  <div class="webmaster">
    <div class="main" v-if="!loading">
      <div class="appBoxs" v-if="rowAppList.length">
        <div
          class="appBox"
          v-for="item in rowAppList"
          :key="item.id"
          @click="jump(item)"
        >
          <ImgDecypt
            :key="item.id"
            :src="item.icon"
            class="appIcon"
          ></ImgDecypt>
          <div class="appName">{{ item.name }}</div>
        </div>
      </div>
      <NoData v-else />
    </div>
    <Loading v-else />
  </div>
</template>
<script>
import { getWebApps } from "@/api/home";
import { jumpAdvs } from "@/utils/getConfig";
import Loading from "@/components/Loading/index.vue";
import NoData from "@/components/NoData/index.vue";

export default {
  name: "webmaster",
  components: {
    ImgDecypt: () => import("@/components/ImgDecypt"),
    Loading,
    NoData,
  },
  data() {
    return {
      rowAppList: [],
      loading: true,
    };
  },
  created() {
    this.getWebApps();
  },
  methods: {
    async getWebApps() {
      let res = await this.$Api(getWebApps);
      this.loading = false;
      if (res && res.code == 200 && res.data) {
        this.rowAppList = res.data.hengApp ? res.data.hengApp : [];
      }
    },
    jump(data) {
      jumpAdvs(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.webmaster {
  height: 100vh;
  width: 100%;
  background-color: rgb(253, 241, 243);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  .main {
    height: 100%;
    .appBoxs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-row-gap: 14px;
      grid-column-gap: 25px;
      padding: 20px 18px 50px;
      .appBox {
        width: 76px;
        .appIcon {
          height: 76px;
          width: 76px;
          overflow: hidden;
          border-radius: 10px;
        }
        .appName {
          font-size: 15px;
          color: rgb(74, 68, 62);
          margin-top: 12px;
          text-align: center;
          overflow: hidden;
          white-space: nowrap; //文本不会换行
        }
      }
    }
  }
}
</style>
