<template>
  <div class="loading_box">
    <img class="loading" slot="loading" :src="loadingSrc" rel="external nofollow">
  </div>
</template>
<script>
export  default  {
  data(){
    return {
      loadingSrc:require("@/assets/gif/loading.gif")
    }
  }
}
</script>
<style lang="scss" scoped>
.loading_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 30px;
  }
}
</style>
