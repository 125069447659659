import { advClick } from "@/api/home";
import { Api } from "@/utils/index";

/**
 * 跳转广告
 * @param {*} item 广告信息
 */
export async function jumpAdvs(item) {
  Api(advClick, item);
  window.open(item.url);
}
