import router from "./router";
//全局路由导航
router.beforeEach((to, from, next) => {
  if (to.query.appId == "28" && to.query.page == "webmaster") {
    next({
      path: "/webmaster",
    });
  } else if (to.query.appId == "28" && to.query.page == "tool") {
    next({
      path: "/tool",
    });
  } else {
    next();
  }
});
