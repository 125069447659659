import store from "@/store/index";

const getters = {
  //接口api
  baseUrl: (state) => {
    return state.app?.baseUrl || "";
  },
  //全局loading
  globalLoading: (state) => {
    return state.app?.globalLoading || false;
  },
  //用户UID
  UID: (state) => {
    return state.app?.UID || "";
  },
  //是否vip
  isVip: (state) => {
    let userInfo = state.user?.userInfo;
    if (typeof userInfo == "string") {
      userInfo = JSON.parse(state.user.userInfo);
    }
    return (userInfo.isVip && userInfo?.vipLevel > 0) || false;
  },
  // 置顶弹窗信息
  setTopData: (state) => {
    return state.user.setTopData;
  },

  //评论弹窗信息
  commentData: (state) => {
    return state.user.commentData;
  },
  //分享弹窗信息
  shareDate: (state) => {
    return state.user.shareDate;
  },
  //vip权限弹窗信息
  vipDate: (state) => {
    return state.user.vipDate;
  },
  //发布已选标签
  tags: (state) => {
    return state.commnity.tags;
  },
  //缓存组件列表
  keepAliveList: (state) => {
    return state.app.keepAliveList;
  },
  //用户信息
  userInfo: (state) => {
    let userInfo = state.user?.userInfo;
    if (typeof userInfo == "string") {
      userInfo = JSON.parse(state.user.userInfo);
    }
    return userInfo;
  },
  //配置信息
  appConfig: (state) => {
    let appConfig = state.app?.appConfig;
    if (typeof appConfig == "string") {
      appConfig = JSON.parse(state.app.appConfig);
    }
    return appConfig;
  },
  //imgapi
  imgApi: (state) => {
    let appConfig = state.app?.appConfig;
    if (typeof appConfig === "string") {
      appConfig = JSON.parse(state.app?.appConfig);
    }
    let imgDomainList = appConfig?.sourceList?.filter(
      (item) => item.type === "IMAGE"
    );
    if (imgDomainList && imgDomainList.length > 0) {
      return imgDomainList[0]?.domain[0]?.url;
    }
    return "";
  },
};

export default getters;
