/**
 * 不经过action的异步
 * @param {Function} fn 异步函数
 * @param {} payload 参数
 * @param {boolean} asyncFlag 是否将请请求设置为同步请求（等待上一个请求结束，下一个请求再继续）
 * @param {Function}  callback 回调函数
 */
import moment from "moment";
import {
    addData,
    closeDB,
    cursorGetData,
    cursorGetDataByIndexAndPage,
    deleteDB,
    getDataByKey,
    getDataCount,
    openDB,
    UpdateData
} from "@/utils/indexedDB";

let isPass = true;
let holdRequest = [];

export async function Api(fn, payload, asyncFlag = false, callback) {
    if (asyncFlag) {
        let ret;
        if (fn) {
            holdRequest.push(fn);
        }
        if (!isPass) return;
        isPass = false;
        if (holdRequest.length > 0) {
            let popRequest = holdRequest.pop();
            try {
                let {
                    data
                } = await popRequest(payload, callback);
                ret = data;
            } catch (err) {
                ret = Promise.resolve(err);
            } finally {
                isPass = true;
                Api(undefined, undefined, true, callback)
            }
            return ret;
        }

    } else {
        let {
            data
        } = await fn(payload, callback)
        return data
    }
}


/**
 *  截取url参数
 * @param {*} name 截取url参数名称
 * @returns
 */
export function getQueryString(name) {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    let r = window.location.search.substring(1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]);
    }
    return null;
}

/**
 * 获取设备类型
 * @returns {string}  //设备类型
 */
export function getDevType() {
    let type = '';
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        type = 'ios';
    } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        type = 'android';
    } else {
        type = 'pc';
    }
    return type;
}

/**
 * 字符串首字母大写
 */
export function firstUpperCase(str) {
    return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

/**
 * 获取北京时间
 * @returns {string}
 */
export function getBeijinTime() {
    return moment().utcOffset(8).format();
}


// 保存滚动条
export async function setScrollTop(className, path, sonPath, scrollTop) {
    let db = await openDB('wbsq_db')
    let data = {
        className: className,
        path: path,
        scrollTop: scrollTop
    }
    if (sonPath) {
        data.sonPath = sonPath;
    }
    UpdateData(db, 'scrollBar', data);
}

// 保存视频历史记录
export async function addVideoHistory(data) {
    let infoData = data;
    infoData.saveTime = Date.now();
    let db = await openDB('wbsq_db', 1);
    try {
        let queryVideo = await getDataByKey(db, 'mediaInfos', infoData.id)
        if (queryVideo) {
            deleteDB(db, 'mediaInfos', infoData.id)
        }
        let count = await getDataCount(db, 'mediaInfos')
        if (count >= 100) {
            let list = await cursorGetDataByIndexAndPage(db, 'mediaInfos', 1, 1, 'next', 'saveTime', infoData.newsType)
            let id = list[0].id;
            deleteDB(db, 'mediaInfos', id)
        }
        addData(db, 'mediaInfos', infoData);
        closeDB(db);
    } catch (error) {
        closeDB(db);
    }
}

//查询视频历史记录
export async function queryHistoryVideo(page, size, newsType = "SP") {
    let db = await openDB('wbsq_db');
    let list = await cursorGetDataByIndexAndPage(db, 'mediaInfos', page, size, 'prev', 'saveTime', newsType)
    return list || []
}
