<template>
  <div class="tool">
    <div class="main" v-if="!loading">
      <div
        class="toolItem"
        v-for="item in rowAppList"
        :key="item.id"
        @click="jump(item)"
      >
        <div class="appLeft">
          <ImgDecypt class="appIcon" :src="item.icon" />
          <div class="appInfo">
            <div class="appName">{{ item.name }}</div>
            <div class="appDesc">{{ item.desc }}</div>
          </div>
        </div>
        <div class="openBtn">打开</div>
      </div>
      <NoData v-if="!rowAppList.length" />
      <div class="sizeBox"></div>
    </div>
    <Loading v-else />
  </div>
</template>
<script>
import { getToolApps } from "@/api/home";
import { jumpAdvs } from "@/utils/getConfig";
import Loading from "@/components/Loading/index.vue";
import NoData from "@/components/NoData/index.vue";

export default {
  name: "tool",
  components: {
    ImgDecypt: () => import("@/components/ImgDecypt"),
    Loading,
    NoData,
  },
  data() {
    return {
      rowAppList: [],
      loading: true,
    };
  },
  created() {
    this.getToolApps();
  },
  methods: {
    async getToolApps() {
      let res = await this.$Api(getToolApps);
      this.loading = false;
      if (res && res.code == 200 && res.data) {
        this.rowAppList = res.data.hengApp ? res.data.hengApp : [];
      }
    },
    jump(data) {
      jumpAdvs(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.tool {
  height: 100%;
  background-color: rgb(253, 241, 243);
  padding: 0 16px;
  .main {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding: 20px 0;
    .toolItem {
      height: 80px;
      border-radius: 20px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      margin-bottom: 16px;
      .appLeft {
        display: flex;
        align-items: center;
        .appIcon {
          height: 48px;
          width: 48px;
          border-radius: 4px;
          overflow: hidden;
          background-color: orange;
        }
        .appInfo {
          margin-left: 12px;
          width: 220px;
          .appName {
            font-size: 15px;
            font-weight: 500;
            color: rgb(34, 34, 34);
            margin-bottom: 4px;
            overflow: hidden;
            white-space: nowrap; //文本不会换行
          }
          .appDesc {
            font-size: 12px;
            color: rgb(155, 150, 145);
            overflow: hidden;
            text-overflow: ellipsis; //文本溢出显示省略号
            white-space: nowrap; //文本不会换行
          }
        }
      }
      .openBtn {
        height: 28px;
        width: 64px;
        border-radius: 4px;
        background: linear-gradient(
          to right,
          rgb(255, 199, 0),
          rgb(255, 126, 53)
        );
        text-align: center;
        line-height: 28px;
        font-size: 13px;
        color: white;
      }
    }
    .sizeBox {
      height: 30px;
    }
  }
}
</style>
