import axios from "@/utils/request";

/*********************** 首页 ******************/
// 获取tab列表
export function getTabs() {
  return axios.get(`/adQuJhKM/542BqCZns8nM`);
}
// 获取app列表
export function getHomeApps(data) {
  return axios.post(`/adQuJhKM/wloOmfnkc8dk/${data}`);
}
// 广告点击统计
export function advClick(data) {
  let config = {
    headers: {
      "log-id": data.id,
      "log-type": "app",
    },
  };
  return axios.get(`/adQuJhKM/dHH9h0Kp1074`, config);
}
// 获取站长推荐列表
export function getWebApps() {
  return axios.post(`/adQuJhKM/vagg81wt46av/WebmasterRecommended`);
}
// 获取实用工具列表
export function getToolApps() {
  return axios.post(`/adQuJhKM/vagg81wt46av/PragmaticTool`);
}

/**
 * 截取URL参数
 * @param name 需要截取url的参数
 * @returns
 */
function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}
// 记录
export function visitRecord() {
  let config = {
    headers: {
      reqLog: "reqLog",
    },
  };
  if (getUrlParam("dc")) {
    config.headers.channel = getUrlParam("dc");
  }
  return axios.get(`/adQuJhKM/lo0mCfBtosTF`, config);
}
