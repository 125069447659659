//兼容浏览器
var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
// eslint-disable-next-line no-unused-vars
var IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction || {
    READ_WRITE: "readwrite"
};
var IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;

/**
 * 打开数据库
 * @param {*} dbName 数据库实例
 * @param {*} version 仓库民
 * @returns
 */
function openDB(dbName, version = 1) {
    return new Promise((resolve, reject) => {
        let db;
        const openRequest = indexedDB.open(dbName, version);
        openRequest.onerror = event => {
            //打开数据库失败
            reject(event)
        };
        openRequest.onsuccess = event => {
            // 打开数据库成功
            db = event.target.result;
            resolve(db)
        };
        // 数据库有更新时使用
        openRequest.onupgradeneeded = function (event) {
            db = event.target.result;
            var objectStore;
            var objectStoreScroll;
            // 如果不存在mediaInfos就创建
            if (!db.objectStoreNames.contains('mediaInfos')) {
                objectStore = db.createObjectStore('mediaInfos', {
                    keyPath: 'id'
                });
            }
            // 创建id为索引
            objectStore.createIndex("id", "id", {
                unique: true
            }),
                // 创建时间为索引
                objectStore.createIndex("saveTime", "saveTime", {
                    unique: true
                })
            // 如果不存在scrollBar就创建
            if (!db.objectStoreNames.contains('scrollBar')) {
                objectStoreScroll = db.createObjectStore('scrollBar', {
                    keyPath: 'path'
                });
            }
            // 创建className为索引
            objectStoreScroll.createIndex("className", "className", {
                unique: false
            })
            objectStoreScroll.createIndex("path", "path", {
                unique: true
            })
            objectStoreScroll.createIndex("pathSon", "pathSon", {
                unique: true
            })
        }
    })
}

/**
 * 插入数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库民
 * @param {*} data 插入的数据
 */
function addData(db, storeName, data) {
    return new Promise((resolve) => {
        let request = db.transaction([storeName], 'readwrite').objectStore(storeName).add(data);
        request.onsuccess = function () {
            // 数据写入成功
            resolve(true)
        };
        request.onerror = function () {
            // 数据写入失败
            resolve(false)
        }
    })
}

/**
 * 更新数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库民
 * @param {*} data 更新数据
 */
function UpdateData(db, storeName, data) {
    return new Promise((resolve) => {
        let request = db.transaction([storeName], 'readwrite').objectStore(storeName).put(data);
        request.onsuccess = function () {
            // 数据更新成功
            resolve(true)
        };
        request.onerror = function (err) {
            // 数据更新失败
            console.log(err);
        }
    })
}

/**
 * 通过主键读取数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库民
 * @param {*} key 主键名
 * @returns
 */
function getDataByKey(db, storeName, key) {
    return new Promise((resolve, reject) => {
        let request = db.transaction([storeName]).objectStore(storeName).get(key)
        request.onsuccess = function () {
            resolve(request.result)
        };
        request.onerror = function (event) {
            // 事务失败
            reject(event)
        }
    })
}

/**
 * 查询所有数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库民
 * @returns 查询所有数据
 */
function getAllData(db, storeName) {
    return new Promise((resolve, reject) => {
        let request = db.transaction([storeName]).objectStore(storeName).getAll()
        request.onsuccess = function () {
            resolve(request.result)
        };
        request.onerror = function (event) {
            // 事务失败
            reject(event)
        }
    })
}

/**
 * 查询仓库多少条数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库民
 * @param {*} count 查询的条数
 */
function getDataCount(db, storeName, count) {
    return new Promise((resolve, reject) => {
        let store = db.transaction(storeName, 'readwrite').objectStore(storeName)
        let request;
        if (count) {
            request = store.count(count)
        }
        request = store.count()
        request.onsuccess = function () {
            resolve(request.result)
        };
        request.onerror = function (event) {
            // 事务失败
            reject(event)
        }
    })
}

/**
 * 通过游标读取数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库民
 * @param {*} date 索引日期
 * @param {*} solt 倒序还是正序
 * @returns 查询所有数据prev  next
 */
function cursorGetData(db, storeName, date, solt) {
    return new Promise((resolve, reject) => {
        let list = [];
        let store = db.transaction(storeName, 'readwrite').objectStore(storeName);
        let index = store.index(date);
        let cursorRequest = index.openCursor(null, solt)
        cursorRequest.onsuccess = function (e) {
            let cursor = e.target.result;
            if (cursor) {
                list.push(cursor.value)
                cursor.continue(); //遍历了指针对象中的所有内容
            } else {
                resolve(list)
            }
        }
        cursorRequest.onerror = function (e) {
            reject(e)
        }
    })
}

/**
 * 通过索引获取数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库民
 * @param {*} indexName 索引名
 * @param {*} indexValue 索引值
 * @returns 通过索引查询的数据
 */
function getDataByIndex(db, storeName, indexName, indexValue) {
    return new Promise((resolve, reject) => {
        let store = db.transaction(storeName, 'readwrite').objectStore(storeName)
        let request = store.index(indexName).get(indexValue);
        request.onsuccess = function () {
            resolve(request.result)
        };
        request.onerror = function (event) {
            // 事务失败
            reject(event)
        }
    })
}


/**
 * //通过索引和游标读取数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库民
 * @param {*} indexName 索引名
 * @param {*} indexValue 索引值
 * @returns 返回一个Promise 查询成功数据或者错误信息
 */
function cursorGetDataBuIndex(db, storeName, indexName, indexValue) {
    return new Promise((resolve, reject) => {
        let list = [];
        let store = db.transaction(storeName, 'readwrite').objectStore(storeName)
        let request = store.index(indexName).openCursor(IDBKeyRange.only(indexValue))
        request.onsuccess = function (e) {
            let cursor = e.target.result;
            if (cursor) {
                list.push(cursor.value)
                cursor.continue(); //遍历了指针对象中的所有内容
            } else {
                resolve(list)
            }
        }
        request.onerror = function (e) {
            reject(e)
        }
    })

}

/**
 *
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库名
 * @param {*} page 页码
 * @param {*} size 每页多少条
 * @param {*} solt 倒序还是正序
 * @param {*} newsType 视频类型
 * @returns 分页查询的数据
 */
function cursorGetDataByIndexAndPage(db, storeName, page, size, solt, date, newsType) {
    return new Promise((resolve, reject) => {
        let list = [];
        let counter = 0; //计数器
        let advanced = true; // 是否跳过多少条数据查询
        let store = db.transaction(storeName, 'readwrite').objectStore(storeName)
        let index = store.index(date);
        let requset = index.openCursor(null, solt)
        requset.onsuccess = function (e) {
            let cursor = e.target.result;
            if (page > 1 && advanced) {
                advanced = false;
                cursor?.advance((page - 1) * size) //跳过多少条数据
                return;
            }
            if (cursor) {
                if (cursor.value.newsType === newsType) {
                    list.push(cursor.value);
                    counter++;
                }
                if (counter < size) {
                    cursor.continue();
                } else {
                    cursor = null;
                    resolve(list)
                }
            } else {
                resolve(list)
            }
        }
        requset.onerror = function (e) {
            reject(e)
        }
    })
}

/**
 *
 * //通过索引和游标删除数据
 * @param {*} db 数据库实例
 * @param {*} storeName 仓库名
 * @param {*} indexName 索引名
 * @param {*} indexValue 索引值
 * @returns 返回一个Promise true 删除成功， false 删除失败
 */
function cursorDelete(db, storeName, indexName, indexValue) {
    return new Promise((resolve, reject) => {
        let store = db.transaction(storeName, 'readwrite').objectStore(storeName)
        let request = store.index(indexName).openCursor(IDBKeyRange.only(indexValue));
        request.onsuccess = function (e) {
            let cursor = e.target.result;
            let deleteRequest;
            if (cursor) {
                deleteRequest = cursor.delete();
                deleteRequest.onerror = function () {
                    // 删除失败
                    resolve(false)
                }
                deleteRequest.onsuccess = function () {
                    // 删除成功
                    resolve(true)
                }
                cursor.continue();
            }
        }
        request.onsuccess = function (e) {
            // 事务失败
            reject(e)
        }
    })
}

/**
 * 通过主键删除数据
 * @param {*} db  数据库实例
 * @param {*} storeName 仓库名
 * @param {*} id 主键ID
 * @returns  true 删除成功
 */
function deleteDB(db, storeName, id) {
    return new Promise((resolve, reject) => {
        let request = db.transaction(storeName, 'readwrite').objectStore(storeName).delete(id);
        request.onsuccess = function () {
            resolve(true)
        };
        request.onerror = function (event) {
            // 事务失败
            reject(event)
        }
    })
}

/**
 * 关闭数据库
 * @param {*} db 数据库实例
 */
function closeDB(db) {
    db.close()
}

export {
    openDB,
    addData,
    getDataByKey,
    getAllData,
    cursorGetData,
    getDataByIndex,
    cursorGetDataBuIndex,
    cursorGetDataByIndexAndPage,
    cursorDelete,
    deleteDB,
    closeDB,
    getDataCount,
    UpdateData
}
